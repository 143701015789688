import {Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppState } from '@app/app.service';
// import {LoginInfoComponent} from "../../user/login-info/login-info.component";
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import {LayoutService} from "@app/shared/layout/layout.service";
import { UserService } from '@app/core/services';
import { environment } from '@env/environment';
import { CommonService } from '@app/shared/common/common.service';
import { NavigationService } from './navigation.service';
import { DomSanitizer } from '@angular/platform-browser';
import { JoyrideService } from 'ngx-joyride';
import { ScriptService } from '@app/shared/scripts/script.service';
import { JoyrideOptions } from 'ngx-joyride/lib/models/joyride-options.class';
import { TutorialEventsService } from '@app/core/services/tutorial-events.service';
import { Tutorial } from './tutorial.model';
import { EmitService } from '@app/ts/services/emit.service';
import { ModalDirective } from 'ngx-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { I18nService } from '@app/shared/i18n';
// import { LayoutService } from '../layout.service';
import * as moment from 'moment';
import { LandingService } from '@app/features/ebill/landing/landing.service';
import { NumberPipe } from '@app/shared/pipes/number.pipe';

declare var $: any;
@Component({

  selector: 'sa-navigation',
  templateUrl: './navigation.component.html',
  styleUrls:['./navigation.component.scss'],
  providers: [NumberPipe],
})
export class NavigationComponent implements OnInit {
  netcomId: any;
  navigationList:any = [];
  isNavLoading: boolean;
  appDataSubscription: Subscription;
  appCode: string;
  appId: number;
  showDefault: boolean;
  appPath: string;
  userName = "";
  accountId:any;
  imageData:any;
  welcome_title = "Welcome";
  stepContent: any;
  welcome_text = "Welcome to GATE2B Application. Let's start!"
  welcome_step: Tutorial = new Tutorial();
  ebill_welcome: Tutorial = new Tutorial();
  interconnect_welcome: Tutorial = new Tutorial();
  inputOpen: boolean;
  permissions:any;
  pagePermission: any;
  companyName: string;
  companyConfirmed: boolean;
  showCompanyAlert: boolean;
  inactiveInterface: boolean = false;
  tutorailLoad: boolean = false;
  eBillData:any = {};
  currency:string = '';
  @ViewChild('tutorialModal') public tutorialModal:ModalDirective;
  tutorial_select: FormGroup = new FormGroup({});
  langChangeSubscription: Subscription;
  lanId: string;
  ebillPermission: boolean = false;
  interConnectPermission: boolean = false;
  tourData: any = {};
  userEbillViewPermission: boolean = false;
  module = 'EDI';
  app_module: any = 'EDI';
  result:any = [];
  ebillDataEvent: any;
  ebillDataPresent: boolean = false;
  ebillErrorPrice:any = environment.EBILL_ERROR_PRICE;
  hasAdminPermission: boolean = false;
  noTour: boolean = false;
  showSupport:boolean = false;

  constructor(private appservice: AppState, public router: Router,private route: ActivatedRoute,private httpClient: HttpClient,private layoutService: LayoutService,private userService:UserService,private commonService: CommonService,private navigationService:NavigationService,private _sanitizer: DomSanitizer,
    private readonly joyrideService: JoyrideService,private scriptService: ScriptService,private tutorialService: TutorialEventsService,private _appService:AppState,
    private emitService: EmitService, private fb: FormBuilder, private i18nService: I18nService,private landingService: LandingService, private numberPipe: NumberPipe) {
    this.netcomId = localStorage.getEncryptedItem('netcomId');
    this.langChangeSubscription = this.i18nService.langChangeEvent.subscribe(langObj => this.onLangChange(langObj)); 
    var self = this;
    this.showSupport = localStorage.getEncryptedItem('domain').indexOf('io-market')!=-1;
    $(document).ready(function($) {
      if (window.history && window.history.pushState) {
        $(window).on('popstate', function() {
          let tutorialStatus = localStorage.getEncryptedItem('hideTutorial') == 'true' ? true : false;
          if( tutorialStatus){
            if( localStorage.getEncryptedItem('tutorialStatus') == 'running'){
              self.joyrideService.closeTour();
              localStorage.setEncryptedItem('tutorialStatus','close');
            }
          }
        });
      }
    });
    if ((this.router.url.indexOf("/settings/") !== -1)) { 
      this.getSettingsNavigationList();
      $('body').addClass('setting-menu');
    }
    else if((this.router.url.indexOf("/ebill/") !== -1)){
      this.getEbillNavigationList();
    }
    else{
      this.getNavigationList();
      $('body').removeClass('setting-menu'); 
    }
    $("body li").first().addClass("active");
    setTimeout(() => {
      this.getTutorialData();
    }, 100);
    let permissionCookie = this.commonService.getCookie(environment.permissions);
    this.permissions = permissionCookie !="" ? this.commonService.decrypt(permissionCookie):'';
    this.hasAdminPermission = this.permissions.indexOf("admin.")!=-1 ? true : false;
    if(this.permissions){
      let pagePermission = this.permissions.split(',');
      this.pagePermission = this.commonService.decrypt(decodeURIComponent(permissionCookie)).split(',');
      // this.pagePermission = ["admin.companyProfile.*","admin.user.*","admin.billing.*","admin.subscription.*","accounts.profile.*","accounts.profile.password.edit","gate2b.dashboard.*","gate2b.purchases.*","gate2b.sales.*","gate2b.connections.*","gate2b.settings.*","gate2b.ebill.pricing.hide"];
      this.userEbillViewPermission = pagePermission.includes("gate2b.ebill.pricing.hide");
    }
    $('body').off('mouseenter', '.ebill_details').on('mouseenter', '.ebill_details', function (event) {
      event.stopPropagation();
      // let style = "height:10px !important;padding:5px";
      let closest_fileType = $(this);
      // var cost_per_transaction = self.eBillData.zeroPrice ?"<div class='ac_div'> <span>"+self.i18nService.getTranslation('navigation_ebill_cost_per_transaction')+": </span> <span  class='common_detail'>"+self.currency + ' '+self.numberPipe.transform(self.eBillData.zeroPrice, this.lanId, false)+ "</span></div>": "<div class='ac_div'> <span>"+self.i18nService.getTranslation('navigation_ebill_cost_per_transaction')+": </span> <span  class='common_detail'>"+self.currency + ' '+self.numberPipe.transform(self.eBillData.price, this.lanId, false)+ "</span></div>"
      // console.log(cost_per_transaction);
        
      $(closest_fileType).append(
          "<div class='idTooltip idTooltip_ebill' style='left:65%; height:auto; text-align: left; padding-left: 5px; width:300px;z-index: 1000' >" +
          "<div class='ac_div'> <span>"+self.i18nService.getTranslation('navigation_ebill_transaction')+": </span> <span  class='common_detail'>"+self.eBillData.count+"</span></div>"+
          // "<div class='ac_typeDiv'><span  class='common_detail'>"+self.eBillData.count+"</span></div>"+
        //  "<div class='ac_div'> <span>"+self.i18nService.getTranslation('navigation_ebill_cost_per_transaction')+": </span> <span  class='common_detail'>"+self.currency + ' '+self.numberPipe.transform(self.eBillData.price, this.lanId, false)+ "</span></div>"+
        // "<div class='ac_typeDiv'> <span  class='common_detail'>"+self.currency + ' '+self.numberPipe.transform(self.eBillData.price, this.lanId, false)+ "</span></div>"+
        // cost_per_transaction+
        // "<div class='ac_div'> <span>"+self.i18nService.getTranslation('navigation_ebill_cost_per_error_transaction')+": </span><span  class='common_detail'>"+self.currency+' '+ self.numberPipe.transform(self.ebillErrorPrice, this.lanId, false)+' '+"</span></div>"+
        // "<div class='ac_typeDiv'> <span  class='common_detail'>"+self.currency+' '+ self.numberPipe.transform(self.ebillErrorPrice, this.lanId, false)+' '+"</span></div>"+
        "<div class='ac_div'> <span>"+self.i18nService.getTranslation('navigation_ebill_total_cost_for_error_transaction')+": </span> <span  class='common_detail'>"+self.currency+' '+ self.numberPipe.transform(((self.eBillData.errorCount * self.ebillErrorPrice)), this.lanId, false)+' '+"</span></div>"+
        "<div class='ac_div'> <span>"+self.i18nService.getTranslation('navigation_ebill_total_cost_for_transaction')+": </span><span  class='common_detail'>"+self.currency+' '+ self.numberPipe.transform(((self.eBillData.successCount * self.eBillData.price)), this.lanId, false)+' '+"</span></div>"+
        "<div class='ac_div'> <span>"+self.i18nService.getTranslation('navigation_ebill_cost')+": </span><span  class='common_detail'>"+self.currency+' '+ self.numberPipe.transform(((self.eBillData.successCount * self.eBillData.price)+(self.eBillData.errorCount * self.ebillErrorPrice)), this.lanId, false)+' '+"</span></div>"+
        // "<div class='ac_typeDiv'> <span  class='common_detail'>"+self.currency+' '+ self.numberPipe.transform(((self.eBillData.successCount * self.eBillData.price)+(self.eBillData.errorCount * self.ebillErrorPrice)), this.lanId, false)+' '+"</span></div>"+
          "</div>"
        );
      
    });
    $('body').off('mouseleave', '.ebill_details').on('mouseleave', '.ebill_details', (event) => {
      $(event.currentTarget).parent().find(".idTooltip_ebill").remove();
    });
    $('body').off('mouseenter', '.ebill_amount').on('mouseenter', '.ebill_amount', function (event) {
      event.stopPropagation();
      let closest_fileType = $(this);
      $(closest_fileType).append(
        "<div class='idTooltip idTooltip_ebill' style='left:40%; height:auto; text-align: left; padding-left: 5px; width:300px;z-index: 1000;bottom:calc("+$(".ebill_amount").height()+"px - 8px)' >" +
        "<div class='ac_div'> <span>"+self.i18nService.getTranslation('navigation_ebill_total_cost_for_error_transaction')+": </span> <span  class='common_detail'>"+self.currency+' '+ self.numberPipe.transform(((self.eBillData.errorCount * self.ebillErrorPrice)), this.lanId, false)+' '+"</span></div>"+
        "<div class='ac_div'> <span>"+self.i18nService.getTranslation('navigation_ebill_total_cost_for_transaction')+": </span><span  class='common_detail'>"+self.currency+' '+ self.numberPipe.transform(((self.eBillData.successCount * self.eBillData.price)), this.lanId, false)+' '+"</span></div>"+
        "</div>"
      );
      
    });
    $('body').off('mouseleave', '.ebill_amount').on('mouseleave', '.ebill_amount', (event) => {
      $(event.currentTarget).parent().find(".idTooltip_ebill").remove();
    });
  }
  ngOnInit() {
    this.ebillPermission = this.permissions.toString().indexOf("ebill.settings.")!=-1 ? true : false;
    // this.ebillPermission = false;
    let interConnect = localStorage.getEncryptedItem('interConnectCompany');
    if( localStorage.getEncryptedItem('hideTutorial') == 'false'){
      this.getTutorialData();
    }
    if( interConnect!= null && JSON.parse(interConnect).length > 0 ){
      this.interConnectPermission = true;
    }
    else{
      this.interConnectPermission = false;
    }
    this.app_module = this.interConnectPermission ? 'INTERCONNECT' :  this.ebillPermission ?  'EBILL' : 'EDI';
    this.module = this.app_module;
    this._appService._navigationNotify$.subscribe(data => { 
      if(data.toString()=='true') {
       this.getNavigationList()
       $('body').removeClass('setting-menu');
      }else if(data.toString()=='false') {
        this.getSettingsNavigationList();
        $('body').addClass('setting-menu');
      }
      else if(data.toString()=='ebill') {
        this.getEbillNavigationList();
        // $('body').addClass('setting-menu');
      }
    })
    this.tutorialService.cast2.subscribe(tutorial => {
      if(tutorial){
        setTimeout(() => {
          this.getTutorialData();
        }, 1000);
      }
     
    })
    this.emitService.checkInactiveInterFaceSubject.subscribe( data => {
      this.getInterfaces();
    });
    this.appDataSubscription = this.layoutService.subscribeAppDataChange(data => {
      let appCode = data.code;
      this.appId = data.id;
      this.appPath = data.path;
      this.showDefault = data.showDefault;
      if (this.appCode !== appCode || this.appId!=undefined) {
        this.appCode = appCode;
        // if (this.appCode !== 'settings') {
        //   this.getNavigationList();   
        //   $('body').removeClass('setting-menu'); 
        // } else {
        //   this.getSettingsNavigationList();
        //   $('body').addClass('setting-menu');
        // }
      }
      // this.setNavid('init','',JSON.parse(localStorage.getItem('appDetail')));
    });
    if( this.ebillPermission )
    this.getBillerInfo();
    let formData = new FormData();
    formData.append('code','EDI')
    let objectUser = this.commonService.createObject(formData);
    let tokenUser = this.commonService.createToken(objectUser);
    let encryptedTokenUser = this.commonService.encrypt(tokenUser);
    let encrDataUser = new FormData();
    encrDataUser.append("encrToken", encryptedTokenUser);
    // this.userService.userData(encrDataUser).subscribe(res=>{
    //   let user = res['data']
    //   this.userName = user[0].username;
    //   localStorage.setEncryptedItem('userData', JSON.stringify(user));
    //   localStorage.setEncryptedItem('isCompanyConfirmed',user[0].isConfirmedParty);
    //   localStorage.setEncryptedItem('isAddressEmpty',user[0].isAddressEmpty);
    //   localStorage.setEncryptedItem('hasPermission',user[0].hasPermission);
    // });
    let userCookie = this.commonService.getCookie(this.commonService.encrypt(environment.userInfo));
    let userData = userCookie ? JSON.parse(this.commonService.decrypt(userCookie)) : "";
    // this.userName = localStorage.getEncryptedItem('userData') ? JSON.parse(localStorage.getEncryptedItem('userData'))[0].username : '';
    // let companyList = localStorage.getEncryptedItem('userData') ? JSON.parse(localStorage.getEncryptedItem('userData'))[0].companyDetails : '';
    // localStorage.getEncryptedItem('userData') ? this.companyName = companyList.filter(item=>item.partyid == localStorage.getEncryptedItem('partyID'))[0]['companyname']: '';
    this.userName = userData.userName ? userData.userName : "";
    this.companyName = userData.companyName ? userData.companyName: "";
    let fetchProfileData = new FormData();
    this.accountId = localStorage.getEncryptedItem('accId');
    fetchProfileData.append("accountId", this.accountId);
    let object = this.commonService.createObject(fetchProfileData);
    let token = this.commonService.createToken(object);
    let encryptedToken = this.commonService.encrypt(token);
    let encrData = new FormData();
    encrData.append("encrToken", encryptedToken);
    this.navigationService.getUserImg(encrData)
    .subscribe(data => {
      if(data['status']){
        this.imageData = this._sanitizer.bypassSecurityTrustResourceUrl(data['data']);
      }
      else{
        this.imageData="";
      }
      
    })
    let formDataEbill = new FormData();
    formDataEbill.append('companyId',localStorage.getEncryptedItem('partyID'))
    formDataEbill.append('netcomId',localStorage.getEncryptedItem('netcomId'))
    let objectEbill = this.commonService.createObject(formDataEbill);
    let tokenEbill = this.commonService.createToken(objectEbill);
    let encryptedTokenEbill = this.commonService.encrypt(tokenEbill);
    let encrDataEbill = new FormData();
    encrDataEbill.append("encrToken", encryptedTokenEbill);
    this.navigationService.getEBillData(encrDataEbill).subscribe(res=>{
      if(res.status){
        this.eBillData = res['data'][0];
        this.currency = JSON.parse(localStorage.getEncryptedItem('userData'))[0].currency;
      }
    })

    setTimeout(() => {
      /* let tutorialStatus = localStorage.getEncryptedItem('hideTutorial') == 'true' ? true : false;
      if(!tutorialStatus)
      this.openPopup();
      else */
      localStorage.setEncryptedItem('tutorialStatus','close');
    });

  }
  getBillerInfo() {
    let sectorData = new FormData();
    sectorData.append('netcomId', localStorage.getEncryptedItem('netcomId'));
    let object = this.commonService.createObject(sectorData);
    let token = this.commonService.createToken(object);
    let encryptedToken = this.commonService.encrypt(token);
    let encrData = new FormData();
    encrData.append('encrToken', encryptedToken);
    this.landingService.getBillerData(encrData).subscribe(res => {

      if (res['message'] != "no_data_found" && res["data"][0].billerid) {
        this.ebillDataPresent = true;
      }
      else
        this.ebillDataPresent = false;
    })
  }
  // setDefaultImg(){
  //  let classData=Array.from($("body")[0].classList);
  //  if(classData.includes('smart-style-1')){

  //  }
  // }
  onLangChange(langObj: any) {
    this.lanId = this.commonService.getCurrentLanguageCode();
    moment.locale(this.lanId);
    langObj.isHandled = true;
    this.initForm();
  }
  getNavigationList(){   
    this.navigationList = [];
      this.httpClient.get("assets/api/jsonData/navigation/commonNavigation.json").subscribe(data =>{ 
        let navData = this.manipulateTreeData(data['DATA']);
        this.navigationList =  navData;  
        this.module = 'edi';
        // for(let j=0;j<data['DATA'].length;j++){
        //   this.navigationList.push(data['DATA'][j]);
        // }
    this.isNavLoading = true;

        setTimeout(() => {
          this.isNavLoading = false;
        })
        
      })
  }
  getSettingsNavigationList(){
    this.navigationList = [];
      this.httpClient.get("assets/api/jsonData/navigation/settingsNavigation.json").subscribe(data =>{ 
        let navData = this.manipulateTreeData(data['DATA']);
        this.navigationList =  navData;
        this.module = 'edi'; 
        // for(let j=0;j<data['DATA'].length;j++){
        //   this.navigationList.push(data['DATA'][j]);
        // }
        this.isNavLoading = true;

        setTimeout(() => {
          this.isNavLoading = false;
        })
        
      })
  }
  manipulateTreeData(tree){
    let arrTree = [];
    let sortedArr = [];
    let retSortedArr = [];
    for (let i in tree) {
      let node = tree[i];
      if(typeof node === 'object'){
        let manipulatedNode = this.manipulateSettingsNode(node);
        arrTree.push(manipulatedNode);
        let pos = manipulatedNode['POS'];
        if (pos) {
          pos = parseInt(pos);
          sortedArr[pos] = manipulatedNode;
        }
        if(manipulatedNode['IMAGE_PATH']){
          var filterValue = manipulatedNode['RIGHTS'];
          this.result = this.pagePermission.filter(permission => permission.startsWith(filterValue));
        }
      }  
    }
    for (let j in sortedArr) {
      if (sortedArr[j]) {
        retSortedArr.push(sortedArr[j]);
      }
    }
    if (arrTree.length === retSortedArr.length) {
      return retSortedArr;
    }
    return arrTree;
  }
  manipulateSettingsNode(node) {
    let manipulatedNode = {
      children: []
    };
    let sortedNode = {
      children: []
    };
    let retSortedNode = {
      children: []
    };
    for (let i in node) {
      let childData = node[i];
      if (typeof (childData) === 'object') {
        let gManiuplatedNode = this.manipulateSettingsNode(childData);
        manipulatedNode.children.push(gManiuplatedNode);
        let pos = gManiuplatedNode['POS'];
        if (pos) {
          pos = parseInt(pos);
          sortedNode.children[pos] = gManiuplatedNode;
        }
      } else {
        manipulatedNode[i] = childData;
        sortedNode[i] = childData;
        retSortedNode[i] = childData;
      }
    }
    for (let j in sortedNode.children) {
      if (sortedNode.children[j]) {
        retSortedNode.children.push(sortedNode.children[j]);
      }
    }
    if (manipulatedNode.children.length === retSortedNode.children.length) {
      return retSortedNode;
    }
    return manipulatedNode;
  }
  setNavid(data,module,appDetail,isEbill?){
    var tempNavId = '';
    if(data == 'init'){
      if(this.navigationList.length != 0){
        this.navigationList.forEach(element => {
          if(element.children.length > 0){
            element.children.forEach(ele => {
              if(ele.children.length > 0){
                ele.children.forEach(e => {
                  if(e.children.length>0){
                    e.children.forEach(el => {
                      if(el.MODULE == window.location.pathname){
                        this.layoutService.setNavId(el.ID,el.MODULE);
                      }
                    });
                  }
                  if(e.MODULE == window.location.pathname){
                    this.layoutService.setNavId(e.ID,e.MODULE);
                  }
                });
              }
              if(ele.MODULE == window.location.pathname){
                this.layoutService.setNavId(ele.ID,ele.MODULE);
              }
            });
          }
          if(element.MODULE == window.location.pathname){
            this.layoutService.setNavId(element.ID,element.MODULE);
          }
        });
      }
      else if(appDetail){
        this.layoutService.setNavId(appDetail.id,appDetail.path);
      }
    }
    else if(module != ''){
      this.layoutService.setNavId(data,module)
    }
    if(isEbill){
      this.getEbillNavigationList();
    }
  }

  goToProfile() {
    window.open(localStorage.getEncryptedItem('ACCOUNTS_URL')+'profile', '_self')
    return false;
  }

  goToAdmin() {
    window.open(localStorage.getEncryptedItem('ADMIN_URL'), '_self')
    return false;
  }
  
  showChat() {
    this.scriptService.load('chat').then(data => {
  }).catch(error => console.log(error));
  }

  goToSupport() {
    window.open(environment.SUPPORT_URL, environment.SUPPORT_URL)
    return false; 
  }
  openPopup(){
    if( this.stepContent ){
      if( this.stepContent["steps"].length == 0 ){
        this.noTour = true;
      }
      if( !this.ebillPermission && !this.interConnectPermission){
        $('input[type=radio]#ebill_radio').attr("disabled",true);
      }
      else{
        $('input[type=radio]#ebill_radio').attr("disabled",false);
      }
      this.tutorialModal.show();
      localStorage.setEncryptedItem('tutorialStatus','running');
    }
    
    // if( !this.interConnectPermission){
    //   $('input[type=radio]#interconnect_radio').attr("disabled",true);
    // }
    // else{
    //   $('input[type=radio]#interconnect_radio').attr("disabled",false);
    // }
    // if( !this.ebillPermission && !this.interConnectPermission){
    //   this.startTour('click');
    // }
    // else{
    //   this.tutorialModal.show();
    // }
    
  }
  startTour(from){
    this.stepContent = this.tourData[this.app_module];
    let tourData = this.stepContent["stepData"];
    let tourSteps = this.stepContent["steps"];
    let activeIndex = -1;
    let configIndex = -1;
    let ebillCreateIndex = -1;
    let ebillEditIndex = -1;
    let permissionCookie = this.commonService.getCookie(environment.permissions);
    this.pagePermission = this.commonService.decrypt(decodeURIComponent(permissionCookie)).split(',');
    // this.pagePermission = ["admin.companyProfile.*","admin.user.*","admin.billing.*","admin.subscription.*","accounts.profile.*","accounts.profile.password.edit","gate2b.dashboard.*","gate2b.purchases.*","gate2b.sales.*","gate2b.connections.*","gate2b.settings.*"];
    Object.keys(this.stepContent["stepData"]).forEach( el =>{
      if(!tourData[el].defaultShow){
        let path = tourData[el].path;
        if( tourSteps.indexOf(path) != -1 ){
          tourSteps = tourSteps.filter(function(e) { return e !== path });
        }
      }
      if( this.app_module =='EBILL' ){
        if( el == 'ebill_biller_create' ){
          ebillCreateIndex = tourSteps.indexOf(tourData[el].path);
        }
        if( el == 'ebill_biller_edit' ){
          ebillEditIndex = tourSteps.indexOf(tourData[el].path);
        }
      }     
      
    });
    if( ebillCreateIndex > -1 && this.ebillDataPresent){
      tourSteps.splice(tourSteps.indexOf('ebill_biller_create@ebill/biller'),1);
      ebillCreateIndex = -1;      
    }
    if( ebillEditIndex > -1 && !this.ebillDataPresent){
      tourSteps.splice(tourSteps.indexOf('ebill_biller_edit@ebill/biller'),1);
      ebillEditIndex = -1;      
    }
    // if( !this.pagePermission.includes("gate2b.settings.*") || this.permissions.toString().indexOf("ebill.settings.")==-1) return; 
    let rideOptions: JoyrideOptions = {
      steps: tourSteps, 
      showCounter: true, 
      stepDefaultPosition: 'top', 
      showPrevButton: true, 
      waitingTime: 1000,
      themeColor: '#080a0a',
      logsEnabled: true,
    }
    // this.welcome_step = JSON.parse(localStorage.getEncryptedItem('stepContent'))["stepData"]["gate2b_welcome"];
    let tutorialData = localStorage.getEncryptedItem('stepContent');
    let b = JSON.parse(decodeURIComponent(escape(window.atob(localStorage.getEncryptedItem('stepContent')))));
    let c = JSON.parse(decodeURIComponent(escape(window.atob(localStorage.getEncryptedItem('stepContent')))))[this.app_module];
    // this.welcome_step = JSON.parse(decodeURIComponent(escape(window.atob(localStorage.getEncryptedItem('stepContent')))))['edi']["stepData"]["gate2b_welcome"];
    // this.ebill_welcome = JSON.parse(decodeURIComponent(escape(window.atob(localStorage.getEncryptedItem('stepContent')))))['ebill']["stepData"]["ebill_welcome"];
    // this.interconnect_welcome = JSON.parse(decodeURIComponent(escape(window.atob(localStorage.getEncryptedItem('stepContent')))))['interconnect']["stepData"]["interconnect_welcome"];
    this.joyrideService.startTour( rideOptions)
    .subscribe(      
        step => {
          window.scrollTo({ top: 0, behavior: 'smooth' });          
          setTimeout(() => {
            if( step.name == 'ebill_wizardBiller' || step.name == 'ebill_biller_create' || step.name == 'ebill_biller_edit'){
              this.emitService.subscribeEventForEbill({s:true,name:step.name});
            }
            if( step.name == 'ebillCommunication' || step.name == 'ebill_wizard' ){
              this.emitService.subscribeEventForEbill({s:false,name:step.name});
            }
          }, 1000);
          if( from == 'click')
            localStorage.setEncryptedItem('tutorialStatus','running');
        }, e => { 
          console.log("ERRRRR")
        }, 
        () => {
          this.completedTour();
      } );
      this.joyrideService.isTourInProgress();
  }
  completedTour(){
    localStorage.setEncryptedItem('tutorialStatus','close');
    this.emitService.subscribeTutorialStatus(true);
    this.joyrideService.closeTour();
  }
  doneTour(){
    let tutorialStatus = localStorage.getEncryptedItem('hideTutorial') == 'true' ? true : false;
    localStorage.setEncryptedItem('tutorialStatus','close');
    // if( !tutorialStatus){
      let formData = new FormData();
      formData.append('accId',localStorage.getEncryptedItem('accId'));
      formData.append("partyId",localStorage.getEncryptedItem('partyID'));
      let object = this.commonService.createObject(formData);
      let token = this.commonService.createToken(object);
      let encryptedToken = this.commonService.encrypt(token);
      let encrData = new FormData();
      encrData.append('encrToken',encryptedToken);
      this.appservice.updateTutorialStatus(encrData).subscribe( data => {
        if( data.status){
          localStorage.setEncryptedItem('tutorialStatus','close');
          this.joyrideService.closeTour();
          localStorage.setEncryptedItem('hideTutorial',true);
          this.emitService.subscribeTutorialStatus(true);
          this.tutorialModal.hide();
        }
      });
    // }
    // else{
    //   
    //   localStorage.setEncryptedItem('tutorialStatus','close');
    //   this.emitService.subscribeTutorialStatus(true);
    //   this.joyrideService.closeTour();
    // }
  }
  saveContent(){
    let stepId = $("textarea.in").attr("id");
    let moduleName;
    let saveContent;
    Object.keys(this.tourData).forEach( a => {      
      Object.keys(this.tourData[a]['stepData'] ).forEach( aa => {
        if( this.tourData[a]['stepData'][aa].label == stepId){
          moduleName = this.tourData[a]['stepData'][aa].appmodule;
          saveContent = this.tourData[moduleName].stepData[stepId];
        }
      })
    })    
    let desc,title;
    if( $("input.tour-title-input").is(":visible") ){
      if($("input.tour-title-input").val())
        title = $("input.tour-title-input").val();
      else
        return;
    }
    else{
      title = saveContent.title
    }
    if($("textarea.in").is(":visible")){
      if( $("textarea.in").val() )
        desc = $("textarea.in").val();
      else
        return;
    }
    else{
      desc = saveContent['description'];
    }
    if($("textarea.in").is(":visible") || $("input.tour-title-input").is(":visible")){
        this.inputOpen = false;
        this.tutorailLoad = true;
        let a = $("textarea.in").val();
        let b = $("input.tour-title-input").val();
        // if( a != "" || b != ""){
          let formData = new FormData();
          let lanId = this.commonService.getCurrentLanguageCode();
          lanId = lanId ? lanId : 'en';
          formData.append('language',lanId.toUpperCase());
          formData.append('id',saveContent.cid);
          formData.append('title',title ? title : saveContent.title);
          formData.append('description',desc ? desc : saveContent['description']);
          formData.append('type','tour');
          formData.append("accId", localStorage.getEncryptedItem('accId'));
          formData.append("partyId",localStorage.getEncryptedItem('partyID'));
          let object = this.commonService.createObject(formData);
          let token = this.commonService.createToken(object);
          let encryptedToken = this.commonService.encrypt(token);
          let encrData = new FormData();
          encrData.append('encrToken',encryptedToken);
          this.appservice.updateTour(encrData).subscribe( data => {
            this.tutorialService.editUser(true);
            if( title){
              $('.joyride-step__title').text(title);
              $('.joyride-step__title').css('display','block');
              $('.tour-title-input').css('display','none')
            }
            if( desc){
              $("span.editInput").html(desc).text();
                //  $("span.editInput").text(desc);
              $('.editInput').css('display','block');
              $("textarea.in").css('display','none');
              
            }
            $('.btn-save').css('display','none');
            setTimeout(() => {
              this.tutorailLoad = false;
            }, 100);
          });
        // }
        
      }
  }

  getTutorialData(){
    let permissionCookie = this.commonService.getCookie(environment.permissions);
    let permission = this.commonService.decrypt(decodeURIComponent(permissionCookie)).split(',');   
    if( Object.keys(this.tourData).length > 0 ){ return;}
    let formData = new FormData();
    let lanId = this.commonService.getCurrentLanguageCode();
    lanId = lanId ? lanId : 'de';
    formData.append('language',lanId.toUpperCase());
    formData.append('type','tour');
    formData.append('fk_application_version',environment.APPLICATION_VERSION);
    formData.append("partyId", localStorage.getEncryptedItem('partyID'));
    formData.append("accId", localStorage.getEncryptedItem('accId'));
    let object = this.commonService.createObject(formData);
    let token = this.commonService.createToken(object);
    let encryptedToken = this.commonService.encrypt(token);
    let encrData = new FormData();
    encrData.append('encrToken',encryptedToken);
    this.appservice.getTourListData(encrData).subscribe( data => {
      this.stepContent =  data["data"];
      this.tourData = this.stepContent["tourData"];
      // Object.keys(this.stepContent['stepData']).forEach((el) => {
      //   this.tourData[this.stepContent['stepData'][el].appmodule] = {
      //     stepData: {},
      //     steps: [],
      //   };
      // });
      // Object.keys(this.stepContent['stepData']).forEach((el) => {
      //   let tourModule = this.stepContent['stepData'][el].appmodule;
      //   var obj = {};
      //   obj[el] = this.stepContent['stepData'][el];
      //   Object.assign(this.tourData[tourModule]['stepData'],obj);
      //   this.tourData[tourModule]['steps'].push(
      //     this.stepContent['stepData'][el].path
      //   );
      // });
      localStorage.setEncryptedItem('stepContent',btoa(unescape(encodeURIComponent(JSON.stringify(this.tourData)))));
      // let appModule = localStorage.getItem('appModule');
      this.appservice.setTourData(this.tourData);
      this.stepContent = this.tourData[this.app_module];
      this.welcome_step = this.tourData['EDI'] ? this.tourData['EDI']['stepData']['gate2b_welcome'] : '';
      this.ebill_welcome = this.ebillPermission ? this.tourData['EBILL'] ? this.tourData['EBILL']['stepData']['ebill_welcome'] : '' : "";
      this.interconnect_welcome = this.interConnectPermission ? this.tourData['INTERCONNECT'] ? this.tourData['INTERCONNECT']['stepData']['interconnect_welcome'] : '' : '';
      
    });   
  }
  getInterfaces(){
    let formData = new FormData();
    formData.append("netcom_id", localStorage.getEncryptedItem('netcomId'));
    this.navigationService.getCommunications(formData).subscribe( data => {
      this.isNavLoading = true;
      let dataArray = data['data'];
      for (let index = 0; index < dataArray.length; index++) {
        if( dataArray[index].status == 0){
          this.inactiveInterface = true;
          break;
        }
        else{
          this.inactiveInterface = false;
        }
        
      }
      dataArray.forEach(element => {
        
      });
      this.isNavLoading = false;
    })
  }
  getEbillNavigationList(){
    this.navigationList = [];
    this.httpClient.get("assets/api/jsonData/navigation/ebill_nav.json").subscribe(data =>{ 
      let navData = this.manipulateTreeData(data['DATA']);
      this.navigationList =  navData;  
      this.module = 'ebill';
      // let permissions = this.commonService.decrypt(String(localStorage.getEncryptedItem('permissions')));   
      let permissionCookie = this.commonService.getCookie(environment.permissions);      
      this.permissions = permissionCookie ? this.commonService.decrypt(permissionCookie):'';

      // for(let j=0;j<data['DATA'].length;j++){
      //   this.navigationList.push(data['DATA'][j]);
      // }
      this.isNavLoading = true;
      setTimeout(() => {
        this.isNavLoading = false;
      })     
    })
  }


  goTo() {
      window.open(localStorage.getEncryptedItem('BILLING_URL')+'user-plans', '_self');
  }

  initForm(){
    let appModule = this.interConnectPermission ? 'INTERCONNECT' : this.ebillPermission ? 'EBILL' : 'EDI';
    this.tutorial_select = this.fb.group({
      tutorial_module: [appModule]
    });
  }
  openTutorial(from){
    this.tutorial_select;
    this.stepContent = this.tourData[this.app_module];
    this.tutorialModal.hide();
    this.stepContent = this.tourData[this.app_module];

    // this.tutorialService.editUser(true);
    setTimeout(() => {
      this.startTour(from);
    });
    
  }

  changeAppModule(appModule,event){
    if( (!this.ebillPermission && appModule == 'EBILL')){
      event.preventDefault();
      return true;
    }
    $('.app-tut').removeClass('active');
    $(event.currentTarget).closest('.app-tut').addClass('active');
    this.app_module = appModule;
    if( (this.ebillPermission && appModule == 'EBILL') || (this.interConnectPermission && appModule == 'INTERCONNECT') || ( appModule == 'EDI')){
      this.stepContent = this.tourData[appModule];
    }
    else{
      this.stepContent = this.tourData['EDI'];
    }
    
  }

  closeModal(){
    localStorage.setEncryptedItem('tutorialStatus','close');
    this.emitService.subscribeTutorialStatus(true);
    this.tutorialModal.hide();
  }
  getShortName(name: string): string {
    const maxLength = 15; 
    if (name.length > maxLength) {
      return name.slice(0, maxLength) + '...';
    }
    return name;
  }
}
