import { Component, OnInit, ViewChild } from "@angular/core";
// import { Router } from "@angular/router";
import { NotificationService } from "@app/core/services/notification.service";
import { I18nService } from '@app/shared/i18n/i18n.service';
import { BsLocaleService, BsModalRef, ModalDirective } from 'ngx-bootstrap';
// import { UserService } from "@app/core/services/user.service";
// import { CommonService } from "@app/core/services/common.service";
// import { environment } from "@env/environment";
declare var $: any;

@Component({
  selector: "sa-clearCache",
  //   template: `
  // <div id="logout" (click)="showPopup()" class="btn-header transparent pull-right">
  //         <span> <a title="Sign Out"><i class="fa fa-sign-out"></i></a> </span>
  //     </div>
  //   `,
  template: `
    <div id="clearCache" (click)="showPopup()" class="transparent">
        <span class="iom_btn nav_btn">
          <a title="{{'common_clearcache' | i18n}}">
            <i class="fa fa-refresh"  style="font-size: 20x;"></i>
            <span id="clearCache_text" style="font-size: 14px;padding-left: 10px;">{{'common_clearcache' | i18n}}</span>
          </a>
        </span>
    </div>
    <div bsModal #smUpdateModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
        aria-hidden="true" id="myModal1">
        <div class="modal-dialog modal-sl modal-dialog-centered">
            <div class="modal-content modalBodyBgColor border" style="padding: 15px !important;  width: 800px;">
            <header class="">                                        
                <div class="pull-right crossDiv">
                    <a (click)="smUpdateModal.hide();" title="{{'common_close' | i18n}}">
                        <i class="fa fa-close crossBtn" aria-hidden="true"></i>
                    </a>
                </div>
                <h4 class="modal_header pl-3">{{'common_clearcache' | i18n}}</h4>
            </header>
                <div class="modal-body modalBody p-0">
                    <div class="modal-body modalBody payBody">
                        <h4>{{'common_clearcache_message'|i18n}}</h4>
                    </div>
                    <div class="MessageBoxButtonSection">
                      <button id="bot1-Msg1" class="btn btn-default btn-sm botTempo iom_btn" (click)="clearCache()"> {{'common_yes' | i18n}}</button>
                      <button id="bot2-Msg1" class="btn btn-default btn-sm botTempo iom_btn" (click)="smUpdateModal.hide();"> {{'common_no' | i18n}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  `,
  styles: ['.crossDiv{margin-top: -10px;margin-right: -7px;}.crossbtnDiv{ margin-top: 12px;margin-right: 2px;}.crossBtn{text-align: right;letter-spacing: 0px; opacity: 1;font-size: 20px;font-weight: bold; }.modal_header {text-align: left;font: normal normal bold 16px/22px Open Sans !important;letter-spacing: 0px;opacity: 1;}']
})
export class ClearCacheComponent implements OnInit {

  public user
  userName = "";
  public modalRef: BsModalRef;
  @ViewChild('smUpdateModal') public smUpdateModal:ModalDirective;

  constructor(
    // private userService: UserService,
    // private router: Router,
    private notificationService: NotificationService,
  //  private _commonService: CommonService,
    private i18nService: I18nService
  ) {
  }

  showPopup() {
    this.smUpdateModal.show();
    $('#myModal1').appendTo("body").modal('show');
    // this.notificationService.smartMessageBox(
    //   {
    //     title:
    //     `<i class='fa fa-refresh txt-color-orangeDark' style='color: #a57225!important;'></i><span  style='font-size: 20px;'> ${this.i18nService.getTranslation("common_clearcache")}</span>`,
    //     content:
    //     "<span  style='font-size: 20px;'>" + this.i18nService.getTranslation('common_clearcache_message') + "</span>",

    //     buttons: "[" + this.i18nService.getTranslation('common_yes') + "][" + this.i18nService.getTranslation('common_no') + "]"
    //   },
    //   ButtonPressed => {
    //     if (ButtonPressed == "Yes" || ButtonPressed == "Ja" || ButtonPressed == "Oui") {
    //       {
            
    //       }
    //     }
    //   }
    // );
  }

  // logout() {
  //   localStorage.removeEncryptedItem('userData');
  //   this.userService.logoutUsers$().subscribe((data)=>{
  //     if(data.status)  
  //      {   
  //       let cookieName = this._commonService.encrypt("userDetails")
  //       // document.cookie = "cookieName=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  //       document.cookie = `${environment.checkCookie}=;domain=${''}; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
  //       this.router.navigate(["/auth/login"]);
  //     }
  //   })
  // }

  clearCache() {
    localStorage.clear();
    let cookies = document.cookie.split("; ");
    for (let c = 0; c < cookies.length; c++) {
      let d = window.location.hostname.split(".");
      while (d.length > 0) {
        let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
        let p = location.pathname.split('/');
        document.cookie = cookieBase + '/';
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/');
          p.pop();
        };
        d.shift();
      }
    }
    location.reload()
  }
  ngOnInit() { }
}
