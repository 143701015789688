import { Component, OnInit, Output, EventEmitter, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";
import {LayoutService} from "@app/shared/layout/layout.service";
import { CommonService } from '@app/shared/common/common.service';
import { AppState } from '@app/app.service';
import { environment } from '@env/environment';
import { TutorialEventsService } from '@app/core/services/tutorial-events.service';
import { Tutorial } from '../../navigation/tutorial.model';
import { Subscription } from 'rxjs';
import { I18nService } from '@app/shared/i18n';
import { SettingMenuService } from './setting-menu.service';
import { EmitService } from '@app/ts/services/emit.service';
import { NavigationService } from '../../navigation/navigation.service';

declare var $: any;
@Component({
  selector: 'sa-setting-menu',
  templateUrl: './setting-menu.component.html',
  host: {
    '(document:click)': 'onClick($event)',
    '(window:resize)': 'onResize($event)'
  }
})
export class SettingMenuComponent implements OnInit {
  @Output() onSettingsSelect = new EventEmitter<any>();

  netcomId: any;
  setSettings:any;
  settingsMenu: boolean = false;
  settings_step : Tutorial = new Tutorial();
  stepSub: any;
  stepContent: any;
  langChangeSubscription: Subscription;
  invitationCount: any = 0;
  showCount: boolean = false;
  inputOpen: boolean;
  admin_url: any;
  billing_Url: any;
  constructor(private router: Router, private layoutService: LayoutService, 
      private commonService: CommonService,private appservice: AppState,
      private _eref: ElementRef,private tutorialService: TutorialEventsService,private i18nService: I18nService,private settingsMenuService: SettingMenuService, private eventEmiter: EmitService,
      private navigationService: NavigationService) {
    this.netcomId = localStorage.getEncryptedItem('netcomId');
    this.langChangeSubscription = this.i18nService.langChangeEvent.subscribe(langObj => this.onLangChange(langObj));
    this.admin_url = localStorage.getEncryptedItem('ADMIN_URL');
    this.billing_Url = localStorage.getEncryptedItem('BILLING_URL');
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.indexOf("/settings/") !== -1 || this.router.url.indexOf("/ebill/")  !== -1) {
          localStorage.setItem("appDetail",JSON.stringify({ code: "settings", showDefault: false }));
          this.layoutService.triggerAppDataChange({ code: "settings", showDefault: false });

          localStorage.setItem("appDetail",JSON.stringify({ code: "ebill", showDefault: false }));
          this.layoutService.triggerAppDataChange({ code: "ebill", showDefault: false });

          this.setSettings = true;
          this.onSettingsSelect.emit(true);

        }else{
          this.setSettings = false;
          this.onSettingsSelect.emit(false);


        }
      }
    });
    
    $('body').off('click','.list-group-item').on('click','.list-group-item',  e=>{
      e.stopPropagation();
      this.settingsMenu = false;
    })
  }
  onLangChange(langObj: any) {
    this.tutorialService.cast2.subscribe( tutorial => {
      if(tutorial){
        this.getTutorialData();
      }
    });
  }

  ngOnInit() {
    //this.onClickSettings();
    // this.getTutorialData();
    
    this.eventEmiter.inviteCodeEvent.subscribe( data =>{
      this.getInvitationCount();
    })    
    
  }
  onNext(){
    this.router.navigate(['/settings/interfaces']);
  }
  redirectInterface(){
    this.router.navigate(['/settings/interfaces']);
  }

  onClickSettings(path) {
    // let defaultModule = this.commonService.getDefaultUrl();
    // if ((this.router.url.indexOf("/steps/") !== -1)||(this.router.url.indexOf("/communications/") !== -1)||(this.router.url.indexOf("/businessConnection/") !== -1)) {
    // if ((this.router.url.indexOf("/settings/") !== -1)) {
    //   // this.layoutService.triggerAppDataChange({ code: defaultModule.module, showDefault: true, path: defaultModule.path });
    //   this.layoutService.triggerAppDataChange({ code: "common", showDefault: true});
    //   this.router.navigate(['/userDashboard/']);
    //   this.setSettings = false;
    //   this.onSettingsSelect.emit(false);

    // } else {
    //   localStorage.setItem("appDetail",JSON.stringify({ code: "settings", showDefault: false }));
    //   this.layoutService.triggerAppDataChange({ code: "settings", showDefault: true});
    //   // this.layoutService.triggerAppDataChange({ code: "settings", showDefault: true,path: "/interFaces/41420-0000411428-92" });
    //   this.router.navigate(['/settings/interfaces/']);
    //   this.setSettings = true;
    //   this.onSettingsSelect.emit(true);


    // }

    localStorage.setItem("appDetail",JSON.stringify({ code: "settings", showDefault: false }));
      this.layoutService.triggerAppDataChange({ code: "settings", showDefault: true});
      // this.layoutService.triggerAppDataChange({ code: "settings", showDefault: true,path: "/interFaces/41420-0000411428-92" });
      this.setSettings = true;
      this.onSettingsSelect.emit(true);
      if(path=='interface') {
        this.router.navigate(['/settings/interfaces/']);
      }
      else if(path=='business partner') {
        this.router.navigate(['/settings/bcManagement/']);
      }
      else if(path=='business connector') {
        this.router.navigate(['/settings/connector/']);
      }
  }

  openSettingsList(){
    this.settingsMenu = !this.settingsMenu
  }

  onClick(e) {
    let hasParent = false;
    var target = $(e.target);  
    if (target.parents('div.header-settings').length) {
        hasParent = true;
    }
    if (!this._eref.nativeElement.contains(e.target)&&(!(hasParent || target.hasClass('header-settings')))){
      this.settingsMenu = false;
      $('.webcomponent-profile').removeClass('menuSelected')
    }
  }
  onResize(e) {
    this.settingsMenu = false;
  }
  goTo(path) {
    switch(path){
      case 'subscription'  : window.open(
        this.billing_Url+'user-plans',
        this.billing_Url+'user-plans'
      ); 
      break;
      case 'billing'  : window.open(
        this.admin_url+'billingOverview', 
        this.admin_url+'billingOverview'
      ); 
      break;
      case 'business'  : window.open(
        this.admin_url+'companyDetails', 
        this.admin_url+'companyDetails'
      ); 
      break;
      case 'user'  : window.open(
        this.admin_url+'userManagement',
        this.admin_url+'userManagement'  
      );
      break;  
      case 'contracts'  : window.open(
        this.admin_url+'contracts',
        this.admin_url+'contracts'  
      );break;
    }
  }
  getTutorialData(){
    this.stepContent = this.appservice.getTourData();
    this.stepContent = this.stepContent['EDI'] ? this.stepContent['EDI'] : JSON.parse(decodeURIComponent(escape(window.atob(localStorage.getEncryptedItem('stepContent')))))['EDI'];
    this.settings_step = this.stepContent.stepData['settings'] ? this.stepContent.stepData['settings'] : ""; 
  }
  getInvitationCount(){
    let formData = new FormData();
    formData.append('invited_netcom_id',localStorage.getEncryptedItem('netcomId'));
    this.settingsMenuService.getInvitationCount(formData).subscribe( (data:any) => {
      if( data.status){
        this.invitationCount = data.count;
      }
      else{
        this.invitationCount = 0;
      }
      if( this.invitationCount > 0){
        this.showCount = true;
      }
      else{
        this.showCount = false;
      }
    });
  }
  saveContent(){
    let stepId = $("textarea.in").attr("id");
    let saveContent = this.stepContent['EDI'].stepData[stepId];
    let desc,title;
    if( $("input.tour-title-input").is(":visible") ){
      if($("input.tour-title-input").val())
        title = $("input.tour-title-input").val();
      else
        return;
    }
    else{
      title = saveContent.title
    }
    if($("textarea.in").is(":visible")){
      if( $("textarea.in").val() )
        desc = $("textarea.in").val();
      else
        return;
    }
    else{
      desc = saveContent['description'];
    }
    if($("textarea.in").is(":visible") || $("input.tour-title-input").is(":visible")){
        this.inputOpen = false;
        let a = $("textarea.in").val();
        let b = $("input.tour-title-input").val();
        // if( a != "" || b != ""){
          let formData = new FormData();
          let lanId = this.commonService.getCurrentLanguageCode();
          lanId = lanId ? lanId : 'en';
          formData.append('language',lanId.toUpperCase());
          formData.append('id',saveContent.cid);
          formData.append('title',title ? title : saveContent.title);
          formData.append('description',desc ? desc : saveContent['description']);
          formData.append('type','tour');
          formData.append("accId", localStorage.getEncryptedItem('accId'));
          formData.append("partyId",localStorage.getEncryptedItem('partyID'));
          let object = this.commonService.createObject(formData);
          let token = this.commonService.createToken(object);
          let encryptedToken = this.commonService.encrypt(token);
          let encrData = new FormData();
          encrData.append('encrToken',encryptedToken);
          this.appservice.updateTour(encrData).subscribe( data => {
            this.tutorialService.editUser(true);
            if( title){
              $('.joyride-step__title').text(title);
              $('.joyride-step__title').css('display','block');
              $('.tour-title-input').css('display','none')
            }
            if( desc){
              $("span.editInput").parseHTML(desc);
              $('.editInput').css('display','block');
              $("textarea.in").css('display','none');
              
            }
            $('.btn-save').css('display','none');
          });
        // }
        
      }
  }
}
