export const environment = {
  production: true,
  firebase: {},
  encryptionKey:'it6Hig8a56YacWPPuduLbA==',
  debug: false,
  log: {
    auth: false,
    store: false,
  },
  smartadmin: {
    api: null,
    db: 'smartadmin-angular'
  },
  readmore_URL:"https://www.io-market.com/loesungen/edi-netzwerk/",
  COOKIE_ENABLED_DOMAINS:['api-business.io-market.com','dhdgu.api.io-market.com','fjy29.api.io-market.com','fkbwl.api.io-market.com','fkbwl.api.epost.gate2b.com'],
  checkCookie:"expireCookie",
  tokenExpireTime:"cookieCreatedTime",
  ticket_URL :"http://api.ticket.g2bngt.io-procurement.com/ws/createTicketFromGate2B.cfc?wsdl&method=createTicketFromGate2B",
  privateKey:{key:"MIIEowIBAAKCAQEAzheqwMQrSFIFkQtsiPFKUDHNSqlae2Hfkfr/7J3cF0hrSfcBHcT/63c3gGt02J0dZE+WWcJ0qt4jNZflxUnzuYpPJw+tv+9Kenw/ghHNKtfiaB20SPl6naOKusPrTbWTjC7MnL3jbs9wBtZxC14vufQ5E1TyHKKxuQtW93ej9OclK/e3aoCv68yhQbHEzS2W8mcp8YfzeyF+BkoMGfPYUgeuV+XLSPrgoK6PBPZlAZe7gSlCwo+bbwg3ShafZcRUMg2ParSP3y1DMaoyxmRERWEChyzbBSYzARrzoV75I4zkzySbnY16VV7Rxtmxv08EGJM89auNx09lpO+fJr30WQIDAQABAoIBAF3ZgTSPIHaqg0ZtwVsLJiAmzam2PTH6e+jqOCj0KgmgPNtN1ABOeYwsmeWtCWSrB3tqkrkFSEOXLMl3Bnc1Gfvf8/OBvEmvGmd0tSbyHE/qR3vKKxMgKKook0BBf6G/khVAKoUmI6+OXyF9/bSDi5BOmr8m66oGnI/7jfrbTJp05qO5VQZDVoc5DxFqDgDNr8pqCZhMaYWXD02Oxs+iiHoiFWGth+wjQVvfmd15Yqvwpb09TMB3YVkQ+cnfI9i07SZZ0/o2b8iEFBQTbhNfHDm8QMRJ5k6lcjbREFIWuh0jI6ti9PFFVYnyyTuRioM15rmmGb9iktmW5V9As2WeZgECgYEA72rLgHNHnIjbI5WzioeztRPpyLM09McjuWrxYOh3UFLJvs2mR8KNJ+7ifhqkZRhLP0T/jnNSiWN67mjKANGClJX1ODnZalXAoz4fGH5tUR7VClCFuRUI3bSYSFSkMwmoBipKWykkrf5TcgO9KO43T0gpRs/VihlbUO2EMdH2NLECgYEA3F36SOFCpFTp0doHolB+KopgP8T7Cc2MbbliuAoejOXdE+mcG4MJ7hdRP9g1y0zPBvD1tHdHfFbP2/wFKupfaYca5N0dWefRL/2inz/bXdpWFCN76Wv7bQGLocSxD9aZEXAgjcHU2ys5vHyTtzMm7JMXezIvevIdrPk/AUN4xCkCgYAJ78TCcAZo2MU/lNb+yOMMgOcTDV0FS76P04BxJTTOgKEe6iockFaLcb60vSZVi8yTs6lnoCGs0hQoEPFRV2C0CgVDcCM15pk4LTvdKGIhG8Z7xxvFsrnLedNYLSsjcyMmHls3rV14WzXbZYxURo7FG5ma9sVGFgdUNIdoCe7OAQKBgHjXSAJjFEpNDhM3fmwxu53UV8dgW1Ea2GDrAglt8ybMfhkHR8WGbBGV08Yw5/7d1cE2rWYS6q5yZHV7k9HeyAd0QPkzj37Xskygx2aQe9jRe9Z7Rt3PiwZRjKJe28GVqkZ3uEJEAldVMsn7fn09naCpYHz8HjFLR56s5zHoL/dRAoGBAIlchmCpCCZeWNxZ+EbGtqJHPCC7tm2symaMydadmNNYvWKE1AznO6if7LrdrmZU0tqJG0ut2bYAinsLei/gUQyOWSUgm6bMQ4lpsBfkCNAxztDviOOwi3Av5aTs6Ic00eMWUHWSwQJIMrPmzQBxzxy9itB6x14UdAw5ivJfkf2D"},
  languageCookie:'languageCookie',
  styleCookie:'styleCookie', 
  FORMATS:{'OpenTrans_Special2.1':'.xml','UBL3.0.1':'.XML','UBL3.1':'.XML','ZUGFERD1.0':'.PDF','UBL2.1':'.XML','ABADOC2.0034':'.pdf','EDIFACTD.96A':'.edi','CXMLWithPDF':'.xml', "CXML1.2":'.xml', 'ETS3.1':'.xml', 'SAP02' :'.xml', 'OpenTrans2.1':'.xml','EDIFACTD.01B':'.edi','YellowBill2.0':'.xml','SignedYellowBill2.0':'.xml','Comarch1.0':'.xml','ETS2.0':'.xml','SAP05':'.xml','QR PDF':'.pdf','Meta Coded PDF':'.pdf' }, 
  WC_APPS:"https://wc.io-market.com/subscribedApps/main.js",
  WC_PROFILE:"https://wc.io-market.com/profile/main.js",
  USER_ACTIVE_CHECK:"isUserActive",
  BEXIO_URL:"https://bexio.io-market.com/",
  SUPPORT_URL:'https://support.io-market.com/#/edi/',
  userInfo:"userInfo",
  APPLICATION_VERSION:"8",
  posfinance_id:"41100000007797776",
  interconnect_posfinance_receive_iomID:"41100000000023711",
  MTC_URL: "https://mtc.io-market.com/#/",
  mtc_cookie: "mtc_cookie",
  EBILL_MAIL:"ebillgateway@io-market.com",
  permissions: 'permissions',
  EBILL_QR_INVOICE: "D_EBILL_QR_PDF_SPEZIFIKATION_DE_2022.PDF",
  color:"",
  EBILL_ERROR_PRICE:"0.20",
  cookieConsent: 'cookieConsent',
  tax:"0.081",
  COMPARE_API: "https://dwncu.api.io-market.com/api/v1/main/",
  parentNetcomId:"parentNetcomId"
};