import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AppState } from "@app/app.service";
import { routerTransition } from "@app/shared/utils/animations";
import { EmitService } from "@app/ts/services/emit.service";

@Component({
  selector: "app-main-layout",
  templateUrl: "./main-layout.component.html",
  styles: [],
  animations: [routerTransition]
})
export class MainLayoutComponent implements OnInit {
  showComponent:boolean = false;
  constructor(private router: Router,private emitService:EmitService,private _appService:AppState) {
    router.events.subscribe((val) => {
      if(val instanceof NavigationEnd){
        let currentUrl = window.location.href;
        let previousUrl = this.emitService.previousUrl;
        if(currentUrl.includes('settings')&&!previousUrl.includes('settings') ){
          this._appService.notify(false);
        }
        else if(currentUrl.includes('ebill')&&!previousUrl.includes('ebill') ){
          this._appService.notify('ebill');
        }
        else if(!currentUrl.includes('settings')&&previousUrl.includes('settings')||!currentUrl.includes('ebill')&&previousUrl.includes('ebill')){
          this._appService.notify(true);
        }
        this.emitService.emitPreviosUrl(val['url'])
        // this.showComponent = this.router.url.indexOf('settings')!=-1?true:false;
        // this.showComponent = this.router.url.indexOf('settings')!=-1?true:(this.router.url=='/settings/bulkinvitations'  ? false : true);
        this.showComponent = this.router.url=='/settings/bulkinvitations' || this.router.url=='/settings/bulktemplate' || this.router.url=='/settings/report' ?false:(this.router.url.indexOf('settings')!=-1  ? true : false);
        // this.showComponent = this.router.url=='/settings/bulkinvitations'  ? false :(this.router.url.indexOf('settings')!=-1?true:false);

        // if(this.router.url == '/settings/bulkinvitations') {
        //   console.log('1');
        //   this.showComponent = false
        // }else if ( this.router.url.indexOf('settings')!=-1 ) {
        //   console.log('2');
        //   this.showComponent = true
        // } else {
        //   console.log('3');
        //   this.showComponent = false
        // }
     
      } 
    })
  }

  ngOnInit() {}

  getState(outlet) {
    if(!outlet.activatedRoute) return;
    let ss = outlet.activatedRoute.snapshot;

    // return unique string that is used as state identifier in router animation
    return (
      outlet.activatedRouteData.state ||
      (ss.url.length
        ? ss.url[0].path
        : ss.parent.url.length
          ? ss.parent.url[0].path
          : null)
    );
  }
}
